<template lang="pug">
v-card(light, flat)
    v-row
        v-col
            .text-h4 InfoScan Updates
    v-row
        v-col
            span Keeping your InfoScan server up to date will not only get you the latest server version, it will also include the latest firmware versions and InfoScan client version.

    v-row
        v-spacer
        v-col.flex-shrink-1.flex-grow-0
            v-card.grey.lighten-4.versionCard(flat, light, width='250', height='125')
                v-card-title
                    span.font-italic Current Server Version
                v-card-text
                    v-skeleton-loader.versionSkeleton(:loading='currentServerVersion==="0.0.0"', type='text', light, width='120')
                        span.versionNumber {{ currentServerVersion }}
        v-col.updateAvailableMessage.flex-shrink-1.flex-grow-0.mx-12
            template(v-if='updateAvailable')
                v-icon(color='green', size='120') arrow_forward
                br
                span.text-no-wrap Update Available!
            template(v-else)
                v-icon(color='green', size='120') check_circle
                br
                span Up to date!
        v-col.flex-shrink-1.flex-grow-0
            v-card.grey.lighten-4.versionCard(flat, light, width='175', height='125')
                v-card-title
                    span.font-italic Latest Version
                v-card-text
                    v-skeleton-loader.versionSkeleton(:loading='availableVersion==="0.0.0"', type='text', light, width='120')
                        span.versionNumber {{ availableVersion }}
        v-spacer
    v-row
        v-btn.releaseButton(text, color='white', :href='releaseNotesUrl', target='_blank') Release Notes
    v-row
        v-col
            .text-h5 Instructions
    template(v-if='customerCanUpdateOverInternet')
        v-row
            v-col
                span Your InfoScan server is able to update over the internet. Follow the instructions below to update your server.
                    v-col.flex
    template(v-else)
        v-row
            v-col(cols='5')
                v-row
                    v-col
                        .text-h5 InfoScan Server Update Files
                v-row
                    v-col
                        span Download the latest version of InfoScan. Installing these files will also update the InfoScan client version available to users.

                v-row
                    v-col.flex
                        .downloadButton(@click="downloadFile")
                            span Download v{{availableVersion}}
            v-spacer
            v-col(cols='5')
                v-row
                    v-col
                        .text-h5 InfoScan Client Download
                v-row
                    v-col
                        span Looking for the latest version of the InfoScan client? The installer for the InfoScan client can be downloaded from your InfoScan server.
                        a(:href="serverURL" target = "_blank") {{ serverURL}}
    v-row
        v-col
            v-timeline(reverse)
                v-timeline-item(v-for='(step, index) in updateSteps', :key='index', :icon='step.icon', icon-color='#3f51b5', large)
                    v-card(color='#3f51b5', dark)
                        v-card-text
                            p.white--text(v-html='step.text')
                            p(v-html='step.subtext')

    v-dialog(v-model='downloadInProgress', persistent, max-width='500')
        v-card(light)
            v-card-title Downloading...
            v-card-text
                v-row
                    v-col
                        v-progress-linear(:value='downloadPercentage', color='brand', striped, height='24')
                            template(v-slot:default='{value}')
                                strong.white--text {{ Math.ceil(value) }}%

    v-overlay(absolute, :value='busy')
        .text-h6 Please Wait...
        v-progress-linear(indeterminate)

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
</template>

<script>
import dti from 'dti';

export default {
    data () {
        return {
            dti,
            message: '',
            user: {},
            downloadPercentage: 0,
            downloadInProgress: false
        };
    },
    methods: {
        /**
         * Downloads the update file for the current customer.
         *
         */
        async downloadFile () {
            this.downloadInProgress = true;
            await this.$nextTick();
            const location = encodeURIComponent(this.customer.customersConfig.storageLocation);
            const version = encodeURIComponent(this.availableVersion);
            const onProgress = (progress) => {
                this.downloadPercentage = progress.loaded / progress.total * 100;
            };
            const fileToDownload = await this.axiosDownload(`downloadFile?location=${location}&version=${version}`, 'application/zip', onProgress);
            this.downloadInProgress = false;
            this.downloadPercentage = 0;
            if (fileToDownload) {
                const url = window.URL.createObjectURL(fileToDownload);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'update.zip');
                document.body.appendChild(link);
                link.click();
            } else {
                this.message = 'The file is unavailable. Contact engineering@dorsettcontrols.com for assistance.';
            }
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        },
        serverURL () {
            return `${this.customerConfig?.domainUrl}/download`;
        },
        releaseNotesUrl () {
            const baseFileName='infoscanreleasenotes/InfoScan Release Notes ';
            const url=`${this.axios.defaults.baseURL}${baseFileName}${this.availableVersion}.pdf`;
            return url;
        },
        currentServerVersion () {
            return this.versionData?.jsCodeServer || '0.0.0';
        },
        availableVersion () {
            return this.versionData?.jsCodeGit || '0.0.0';
        },
        customerId () {
            return this.$store.state.customerId;
        },
        customer () {
            return this.$store.state.customerData;
        },
        customerConfig () {
            return this.customer?.customersConfig;
        },
        versionData () {
            return this.customer?.customersVersion;
        },
        updateAvailable () {
            if (this.currentServerVersion === this.availableVersion) {
                return false;
            }
            const current = this.currentServerVersion.split('.');
            const latest = this.availableVersion.split('.');

            for (let i = 0; i < current.length; i++) {
                const currentVersionSegment = parseInt(current[i]);
                const latestVersionSegment = parseInt(latest[i]);
                if (latestVersionSegment === currentVersionSegment) {
                    continue;
                } else if (latestVersionSegment > currentVersionSegment) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        },
        customerHasPublicURl () {
            return this.customerConfig?.isPublic;
        },
        customerCanUpdateOverInternet () {
            return this.customerConfig?.hasInternet;
        },
        updateSteps () {
            if (this.customerCanUpdateOverInternet) {
                return [
                    {
                        text: '1. Log into InfoScan using your InfoScan client.',
                        subtext: '',
                        icon: 'vpn_key'
                    },
                    {
                        text: '2. Open the System Preferences application.',
                        subtext: 'This application is only available if you are a System Administrator. Contact your System Administrator if you don\'t have access to this.',
                        icon: 'settings'
                    },
                    {
                        text: '3. Navigate to the Maintenance tab of the System Preferences application.',
                        subtext: '',
                        icon: 'build'
                    },
                    {
                        text: '4. Click the "Update Now" button.',
                        subtext: 'A message will be sent out to all logged-in users warning them of the upcoming update and their app\'s status bar will turn yellow. After 1 minute, the server will shut down and begin the update process.',
                        icon: 'mouse'
                    }
                ];
            } else {
                return [
                    {
                        text: '1. Click the Download link above to download the install files.',
                        subtext: 'A single file called <span data-v-4c0d0f18="" class="font-italic">update.zip </span><span data-v-4c0d0f18="">will be downloaded.</span>',
                        icon: 'download'
                    },
                    {
                        text: '2. Move the file to the <span data-v-4c0d0f18="" class="font-italic">D:/Update </span><span data-v-4c0d0f18="">folder on the primary InfoScan server.</span>',
                        subtext: 'If folder does not exist, create it. If the folder has contents, remove all before moving the file.',
                        icon: 'content_copy'
                    },
                    {
                        text: '3. Unzip the file to the <span data-v-4c0d0f18="" class="font-italic">D:/Update </span><span data-v-4c0d0f18="">directory</span>.',
                        subtext: 'Among the extracted files, Two should always exist: <span data-v-4c0d0f18="" class="font-italic">infoscan.zip </span><span data-v-4c0d0f18="">and </span><span data-v-4c0d0f18="" class="font-italic">startOffline.ps1</span>.',
                        icon: 'folder_zip'
                    },
                    {
                        text: '4. Open an administrative PowerShell window.',
                        subtext: 'Press Win + X and select "Windows PowerShell (Admin)" from the menu.',
                        icon: 'settings'
                    },
                    {
                        text: '5. Navigate to the D:/Update directory using:',
                        subtext: ' cd D:/Update',
                        icon: 'folder'
                    },
                    {
                        text: '6. Run the update script by running:',
                        subtext: '.\\startOffline.ps1',
                        icon: 'play_arrow'
                    },
                    {
                        text: '7. Wait for the process to complete.',
                        subtext: 'The process may take up to 20 minutes to finish.',
                        icon: 'hourglass_empty'
                    },
                    {
                        text: 'If you encounter an execution policy error, set the execution policy to unrestricted by running the following command in PowerShell as an administrator:',
                        subtext: 'Set-ExecutionPolicy Unrestricted',
                        icon: 'settings'
                    },
                    {
                        text: 'If you are unable to change the execution policy, please contact your server administrator for assistance.',
                        subtext: '',
                        icon: 'contact_support'
                    },
                ];
            }
        }
    },
    watch: {
    },
    mounted () {
        this.user = this.$store.state.fullUser;
        window.infoscanupdatesvm = this;
    },
};
</script>

<style lang="scss" scoped>
.editButtons {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
}

.versionCard {
    .v-card__text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .versionNumber {
            font-weight: bold;
            font-size: 40px;
        }
    }
}

.updateAvailableMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

a {
    color: blue;
    text-decoration: underline;
    padding-left: 5px;
}
</style>

<style lang="scss">
.downloadButton,
.releaseButton {
    height: 36px;
    width: 164px;
    border-radius: 4px;
    background-color: #3f51b5;
    color: white;
    font-weight: 500;
    text-decoration: none !important;
    display: flex;
    flex-direction: row;
    justify-content: center;

    span {
        align-self: center;
    }

    &:hover {
        cursor: pointer;
        color: #a8b0db;
    }
}

.v-timeline {
    margin-top: 50px;
}

.v-timeline-item {
    margin-top: -50px;
}

.versionSkeleton {
    .v-skeleton-loader__text {
        height: 48px !important;
        border-radius: 24px;
    }
}
</style>
